@media (max-width: 900px) { 
.slider-container {
    width: 90%;
    height: 80%;
}
}
@media (min-width: 900px) { 
    .slider-container {
        width: 50%;
        height: 50%;
    }
    }

.slider-item-div {
    padding: 20px;
    background-color: white;
    text-align: center;
    height: 100%;
    width: 100%;
}

.carousel-style {
    height: 100% !important;
}

.image-container {
    display: flex;
    justify-content: center;
    background: url("./../public/roboimages/RobotBackground.png");
}